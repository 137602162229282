import dayjs from "dayjs";

export const getLocalDate = (
  date: string | Date,
  withTime: boolean = false
): string =>
  withTime
    ? `${new Date(date).toLocaleDateString()} ${new Date(
        date
      ).toLocaleTimeString()}`
    : new Date(date).toLocaleDateString();

export const formatDate = (date: string | Date): string => {
  const offsetInMinutes = dayjs(date).utcOffset();
  return dayjs(date).add(offsetInMinutes, "minutes").format("YYYY-MM-DD");
};

export const getUtcDate = (dateString: string) => {
  const localDate = dayjs(dateString);
  return dayjs(localDate).toISOString();
};
